import VBCWebSDK from '@vonage/vbc-web-sdk';

let vbcSDK;

export function initVBCWebSDK() {
  if (process.env.VUE_APP_DEV_MODE) {
    vbcSDK = {
      initialize: () => ({ accountId: process.env.VUE_APP_VBC_ACCOUNT_ID }),
      ready: () => ({}),
      error: () => ({}),
    };
  } else {
    vbcSDK = new VBCWebSDK({ developmentMode: true });
  }
}

export { vbcSDK };
