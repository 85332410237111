<template>
  <div id="main-app-view">
    <vwc-snackbar
      timeoutms="5000"
      icon="warning-solid"
      message="There was a problem, Please try again"
      dismissible="true"
      position="TOP-END"
      connotation="warning"
      :open="portalUrlLoadingState === PORTAL_URL_LOADING_STATE.failure"
    ></vwc-snackbar>
    <div class="title-container">
      <h1 class="page-title">VBC for Microsoft Teams</h1>
      <div>
        <p class="help-text">
          Need help? Just
          <a class="support-link" target="_blank" href="https://businesssupport.vonage.com/articles/answer/Teams-Integrations">click here</a>
        </p>
      </div>
    </div>
    <StatusLayout v-if="isAppReady && syncStatusState === SYNC_STATUS_STATE.success" />
  </div>
</template>

<script>
import StatusLayout from './components/StatusLayout.vue';
import '@vonage/vwc-snackbar';
import '@vonage/vwc-button';
import { mapActions, mapGetters } from 'vuex';
import { SYNC_STATUS_STATE } from './store/consts';
import { PORTAL_URL_LOADING_STATE } from './store/consts';
import { initVBCWebSDK, vbcSDK } from '@/vbc-sdk.js';
import { registerNote, registerNumberField } from '@vonage/vivid';

registerNumberField('vwc-3');
registerNote('vwc-3');

export default {
  name: 'App',

  data() {
    return {
      isAppReady: false,
      SYNC_STATUS_STATE,
      PORTAL_URL_LOADING_STATE,
    };
  },

  components: {
    StatusLayout,
  },

  async beforeCreate() {
    initVBCWebSDK();
  },

  async created() {
    const payload = await vbcSDK.initialize();
    const accessToken = payload.token;
    const accountId = payload.accountId;
    await this.setInitialState({ accountId, accessToken });
    await this.getSyncStatus();
    await vbcSDK.ready();
    this.isAppReady = true;
  },

  computed: {
    ...mapGetters(['syncStatusState', 'portalUrlLoadingState']),
  },

  methods: {
    ...mapActions(['setInitialState', 'getSyncStatus']),
  },

  watch: {
    syncStatusState() {
      if (this.syncStatusState === SYNC_STATUS_STATE.failure) {
        vbcSDK.error();
      }
    },
  },
};
</script>

<style>
body {
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

#main-app-view {
  max-width: 75%;
}

.page-title {
  font: 450 condensed 29px / 34px SpeziaWebVariable;
  letter-spacing: -1.5px;
  display: block;
  margin-bottom: 5px;
  font-size: 32px;
}

.title-container {
  margin-bottom: 24px;
}

.support-link {
  color: #871eff;
}

.help-text {
  font: 300 condensed 14px / 24px SpeziaWebVariable;
  line-height: 20px;
  margin: 0px;
}
</style>

<style lang="css" src="../node_modules/@vonage/vivid/styles/tokens/theme-light.css"></style>
<style lang="css" src="../node_modules/@vonage/vivid/styles/fonts/spezia-variable.css"></style>
