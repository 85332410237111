import { get } from 'lodash';

export default {
  SET_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
  },

  SET_SYNC_STATUS(state, syncStatus) {
    const licenses = get(syncStatus, 'licenses', []);
    state.licensesError = get(syncStatus, 'licensesError', false);
    state.syncStatus = { ...syncStatus, licenses };
  },

  SET_SYNC_STATUS_STATE(state, syncStatusState) {
    state.syncStatusState = syncStatusState;
  },

  SET_PORTAL_URL_LOADING(state, portalUrlLoadingState) {
    state.portalUrlLoadingState = portalUrlLoadingState;
  },

  SET_MY_APPS_LOADING(state, myAppsLoadingState) {
    state.myAppsLoadingState = myAppsLoadingState;
  },

  SET_MY_APPS(state, myApps) {
    state.myApps = myApps;
  },
};
