export default function getInitialState() {
  return {
    credentials: {
      accountId: '',
      accessToken: '',
    },
    syncStatus: {},
    syncStatusState: '',
    portalUrlLoadingState: '',
    licensesError: false,
    myAppsLoadingState: '',
    myApps: [],
  };
}
