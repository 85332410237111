import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;

function buildAuthenticationHeaders(credentials) {
  if (process.env.VUE_APP_DEV_MODE) {
    return {
      'x-von-user-id': process.env.VUE_APP_VBC_USER_ID,
    };
  }

  return {
    Authorization: `Bearer ${credentials.accessToken}`,
  };
}

export async function getSyncStatus(credentials) {
  const response = await axios.get(`/v1/accounts/${credentials.accountId}/sync-status`, {
    headers: buildAuthenticationHeaders(credentials),
  });
  return response.data;
}

export async function getPortalUrl(credentials) {
  const response = await axios.get(`/v1/accounts/${credentials.accountId}/portal-url`, {
    headers: buildAuthenticationHeaders(credentials),
  });
  return response.data.url;
}

export const getMyApps = async (credentials) => {
  const response = await axios({
    method: 'get',
    baseURL: process.env.VUE_APP_APP_CENTER_API_SERVER,
    url: `/accounts/${credentials.accountId}/apps-config`,
    headers: buildAuthenticationHeaders(credentials),
  });
  return Object.values(response.data);
};
