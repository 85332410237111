import * as api from './api';

import { MY_APPS_LOADING_STATE, PORTAL_URL_LOADING_STATE, SYNC_STATUS_STATE } from './consts';
import { vbcSDK } from "@/vbc-sdk.js";

async function sendAmplitudeEvent(payload) {
  const event = "amplitudeEvent"
  await vbcSDK.sendCustomEvent(event, payload);
}

export default {
  async setInitialState({ commit }, { accountId, accessToken }) {
    commit('SET_CREDENTIALS', { accountId, accessToken });
  },

  async getSyncStatus({ commit, state }) {
    try {
      const response = await api.getSyncStatus(state.credentials);
      commit('SET_SYNC_STATUS', response);
      commit('SET_SYNC_STATUS_STATE', SYNC_STATUS_STATE.success);
    } catch (e) {
      commit('SET_SYNC_STATUS_STATE', SYNC_STATUS_STATE.failure);
      const event_payload = {
        eventName: 'Could not get sync status',
        eventProperties: {},
      }
      await sendAmplitudeEvent(event_payload)
    }
  },

  async getPortalUrl({ commit, state }) {
    try {
      commit('SET_PORTAL_URL_LOADING', PORTAL_URL_LOADING_STATE.pending);
      const portalUrl = await api.getPortalUrl(state.credentials);

      commit('SET_PORTAL_URL_LOADING', PORTAL_URL_LOADING_STATE.default);
      window.open(portalUrl, '_blank', 'noopener');
    } catch (e) {
      commit('SET_PORTAL_URL_LOADING', PORTAL_URL_LOADING_STATE.failure);
    }
  },

  async getMyApps({ commit, state }) {
    try {
      const response = await api.getMyApps(state.credentials);
      commit('SET_MY_APPS', response);
      commit('SET_MY_APPS_LOADING', MY_APPS_LOADING_STATE.success);
    } catch (e) {
      commit('SET_MY_APPS_LOADING', MY_APPS_LOADING_STATE.failure);
    }
  },
};
