export default {
  syncStatus(state) {
    return state.syncStatus;
  },
  syncStatusState(state) {
    return state.syncStatusState;
  },
  portalUrlLoadingState(state) {
    return state.portalUrlLoadingState;
  },
  licensesError(state) {
    return state.licensesError;
  },
  myAppsLoadingState(state) {
    return state.myAppsLoadingState;
  },
  myApps(state) {
    return state.myApps;
  },
};
