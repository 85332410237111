import { createStore } from 'vuex';
import getInitialState from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default createStore({
  state: getInitialState(),
  mutations,
  actions,
  getters
})
