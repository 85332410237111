<template>
  <div>
    <div v-if="licensesError" class="loading-container">
      <img src="../../../common/client/assets/images/img-error-emoji.svg" alt="error" class="error-image-size" />
      <p class="help-text">Whoops, licenses data failed to load! Please contact support.</p>
    </div>
    <vwc-layout v-else column-basis="block" column-spacing="md">
      <div>
        <h4 class="status-title section-title">Account Status</h4>
        <section class="story">
          <div class="story-items">
            <h3 class="status-title">Status</h3>
            <div class="status-container">
              <div class="status-row" v-for="(value, key) in displayFields" :key="key">
                <p class="status-key">{{ key }}</p>
                <span class="status-value account-value">{{ value }}</span>
              </div>
            </div>

            <vwc-button
              :label="syncStatus.isSetup ? 'Manage Users' : 'Assign Users'"
              :disabled="portalUrlLoadingState === PORTAL_URL_LOADING_STATE.pending"
              layout="filled"
              type="submit"
              unelevated=""
              @click="syncStatus.acceptedInvitation ? openPortalInNewTab() : showModal()"
            >
              <vwc-circular-progress
                slot="trailingIcon"
                v-if="portalUrlLoadingState === PORTAL_URL_LOADING_STATE.pending"
                indeterminate=""
                density="-7.5"
                progress="0"
              ></vwc-circular-progress>
              <button type="submit" style="display: none"></button>
            </vwc-button>
          </div>
        </section>
      </div>
      <div>
        <h4 class="products-title status-title section-title">Microsoft Teams Products</h4>
        <div v-for="(licenseData, key) in displayLicensesStatus" :key="key">
          <section class="story ms-product">
            <div class="story-items">
              <h3 class="status-title">{{ licenseData.productLabel }}</h3>
              <div class="status-container">
                <div class="status-row">
                  <p class="status-key">Licenses:</p>
                  <p class="status-value">{{ licenseData.total }}</p>
                </div>
                <div class="status-row">
                  <p class="status-key">Currently in use:</p>
                  <p class="status-value">{{ licenseData.used }}</p>
                </div>
              </div>
              <div class="licenses-quantity">
                <vwc-3-number-field class="quantity-field" value="1" min="1" slot="small" />
                <vwc-button
                  label="Add License"
                  class="add-license-button"
                  :disabled="portalUrlLoadingState === PORTAL_URL_LOADING_STATE.pending"
                  layout="filled"
                  type="submit"
                  unelevated=""
                  @click="syncStatus.acceptedInvitation || true ? openPortalStore($event, licenseData.product) : showModal()"
                >
                  <vwc-circular-progress
                    slot="trailingIcon"
                    v-if="portalUrlLoadingState === PORTAL_URL_LOADING_STATE.pending"
                    indeterminate=""
                    density="-7.5"
                    progress="0"
                  ></vwc-circular-progress>
                  <button type="submit" style="display: none"></button>
                </vwc-button>
              </div>
            </div>
          </section>
        </div>
      </div>

      <vwc-dialog heading="Note" scrimclickaction="" ref="beforeFirstLoginModal" closeButton="true">
        <div>
          Please sign in with your O365 Global Admin credentials to initiate the setup. These are only used during setup.
          <br />
          For more information, please read
          <a href="https://businesssupport.vonage.com/articles/answer/Vonage-for-Microsoft-Teams-V" target="_blank">our article on setup</a>
        </div>
        <vwc-button slot="primaryAction" dialogAction="ok" type="submit" layout="filled" @click="getPortalUrl">
          OK
          <button type="submit" style="display: none"></button>
        </vwc-button>
      </vwc-dialog>
    </vwc-layout>
  </div>
</template>

<script>
import '@vonage/vwc-button';
import '@vonage/vwc-layout';
import '@vonage/vwc-circular-progress';
import '@vonage/vwc-note';
import '@vonage/vwc-dialog';

import { get, find } from 'lodash';

import { mapActions, mapGetters } from 'vuex';
import { PORTAL_URL_LOADING_STATE, VBC_FOR_MS_TEAMS_SKU, VBC_VOICE_FOR_MS_TEAMS_SKU, PRODUCT_TO_LABEL } from '../store/consts';
import { format, parseISO } from 'date-fns';

export default {
  name: 'StatusLayout',

  data() {
    return {
      PORTAL_URL_LOADING_STATE,
      ADMIN_PORTAL_URL: process.env.VUE_APP_ADMIN_PORTAL_URL,
    };
  },

  computed: {
    ...mapGetters(['syncStatus', 'portalUrlLoadingState', 'licensesError']),

    isSetup() {
      return this.syncStatus.isSetup && this.syncStatus.acceptedInvitation;
    },

    displayFields() {
      const placeHolder = '-------';
      return {
        'Last Sync:': (this.syncStatus.lastSyncTime && format(parseISO(this.syncStatus.lastSyncTime), 'MM/dd/yyyy h:mm:ss a')) || placeHolder,
        'Current Status:': this.syncStatus.lastSyncStatus || placeHolder,
        'Account Name:': this.syncStatus.accountName || placeHolder,
      };
    },

    displayLicensesStatus() {
      const msTeamsLicenses = find(this.syncStatus.licenses, (licenses) => licenses.product == VBC_FOR_MS_TEAMS_SKU);
      const vbcVoiceLicenses = find(this.syncStatus.licenses, (licenses) => licenses.product == VBC_VOICE_FOR_MS_TEAMS_SKU);
      return [msTeamsLicenses, vbcVoiceLicenses]
        .filter((licenses) => !!licenses)
        .map((licenses) => {
          licenses.productLabel = PRODUCT_TO_LABEL[licenses.product];
          return licenses;
        });
    },
  },

  methods: {
    ...mapActions(['getPortalUrl']),

    showModal() {
      this.$refs.beforeFirstLoginModal.show();
    },

    openPortalInNewTab() {
      window.open(this.syncStatus.portalUrl, '_blank', 'noopener');
    },

    openPortalStore(event, product) {
      const quantity = get(event, 'target.parentNode.childNodes.0.value', 1);
      window.open(`${this.ADMIN_PORTAL_URL}/management/m/addons?itemToFilter=${product}&addProductToCart=true&productQuantity=${quantity}`);
    },
  },
};
</script>

<style>
.loading-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-image-size {
  width: 81px;
  height: 96px;
}

.help-text {
  font: 300 condensed 14px / 24px SpeziaWebVariable;
  line-height: 20px;
  margin: 0px;
}

.status-title {
  font: 450 condensed 20px / 23px SpeziaWebVariable;
  letter-spacing: -1px;
  display: block;
  margin: 0px;
  padding: 0px;
}

.products-title {
  padding-top: 8px !important;
}

h4.status-title {
  padding: 12px 0 12px 0;
}

.story {
  background-color: var(--vvd-color-neutral-10);
  color: var(--on-connotation);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px var(--vvd-color-neutral-20);
  padding-top: 0;
  box-sizing: border-box;
  max-width: min(80%, 520px);
}

.status-row {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.status-row p {
  line-height: 0;
}

.status-key {
  font: 400 ultra-condensed 14px / 24px SpeziaWebVariable;
  flex: 0 0 60%;
  max-width: 60%;
  color: var(--vvd-color-neutral-70);
}

.status-value {
  font: 600 ultra-condensed 14px / 24px SpeziaWebVariable;
  flex: 0 0 40%;
  max-width: 40%;
}

.account-value {
  overflow: auto;
}

.status-container {
  margin: 16px 0 16px 0;
}

.section-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.ms-product {
  margin-bottom: 20px;
}

.story-items {
  padding: 24px;
}

vwc-button {
  font-size: 14px;
}

vwc-button.add-license-button {
  border: 1px solid;
  border-radius: 7px;
}

.licenses-quantity {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px;
  gap: 12px;
}

.quantity-field {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #929292;
  border-radius: 6px;
  width: 110px;
  font: 400 ultra-condensed 14px / 24px SpeziaWebVariable;
}
</style>
