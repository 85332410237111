export const SYNC_STATUS_STATE = Object.freeze({
  failure: 'failed',
  success: 'success',
});

export const PORTAL_URL_LOADING_STATE = Object.freeze({
  failure: 'failed',
  pending: 'pending',
  default: '',
});

export const MY_APPS_LOADING_STATE = Object.freeze({
  failure: 'failed',
  success: 'success',
});

export const VBC_FOR_MS_TEAMS_SKU = 'VIS000011';
export const VBC_VOICE_FOR_MS_TEAMS_SKU = 'VIS000012';
export const PRODUCT_TO_LABEL = Object.freeze({
  [VBC_FOR_MS_TEAMS_SKU]: 'VBC for Microsoft Teams',
  [VBC_VOICE_FOR_MS_TEAMS_SKU]: 'VBC Voice for Microsoft Teams',
});
